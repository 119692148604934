
.theme-bg{
    background-color: $theme-color;
    color: #fff;
    p,h1,h2,h3,h4{
        color: #fff;
    }
    .service__style--2 {
        svg{
            fill: #fff;
        }
        &::before{
            background-image: linear-gradient(to right, #fff, #fff);
        }
        &:hover{
            .content {
                h3.title,.tech-title.title,.h3.title {
                    color: #1f1f25;
                }
               
            }
            svg{
                fill: #1f1f25;
            }

        }
    }
    .btn-custom{
        color: #fff;
        border: 2px solid #ffff;
        &:hover{
            color: #fff;
        }
    }
}


.terms-wrapper{
    ul {
        li{
            margin-bottom: 15px;
            font-size: 18px;
            line-height: 30px;
        }
    }
}

.copyright-text {
    a{
        
        color: #c6c9d8;
        font-size: 13px;
        opacity: .75;
        line-height: 30px;
        margin: 0 0 0 15px;
        @media(max-width:1500px){
            font-size: 12px;
        }
        &:hover{
            color: #ff6f3b;
        }
        @media #{$sm-layout} {
            margin: 0 0 0 0;
        }
    }
    p{
        @media #{$sm-layout} {
          margin-top: 15px;
        }
    }
}
body.modal-open {
    padding-right: 0px !important;
}