// Faq Style 

.custom-accordion{
    .accordion-collapse.collapse.show{
        border-bottom: 2px solid rgba(0,0,0,0.08);
        padding-bottom: 30px;
    }
    .accordion{
        border: none;
    }
    h2.accordion-header{
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 0;
        @media #{$sm-layout} {
            // font-size: 16px;
            // line-height: 25px;
        }

        button.accordion-button{
            color: #ff6f3b;
            border: none;
            width: 100%;
            text-align: left;
            padding-right: 50px;
            padding-top: 30px;
            padding-bottom: 30px;
            border-bottom: 0 solid rgba(0,0,0,0.08);
            position: relative;
            &::after{
                content: "";
                width: 30px;
                height: 30px;
                position: absolute;
                right: 0;
                left: auto;
                top: 50%;
                margin-top: -15px;
                border: 2px solid #ff6f3b;
                border-radius: 30px 30px;
            }
            &::before{
                content: "-";
                position: absolute;
                right: 11px;
                top: 50%;
                margin-top: -16px;
                color: #ff6f3b;
                border-radius: 30px 30px;

            }

        }
        .accordion-button.collapsed{
            border-bottom: 2px solid rgba(0,0,0,0.08);
            position: relative;
            color: #1d1d24;

            &::after{
                content: "";
                width: 30px;
                height: 30px;
                position: absolute;
                right: 0;
                left: auto;
                top: 50%;
                margin-top: -15px;
                border: 2px solid #888;
                border-radius: 30px 30px;      
            }
                    &::before{
                content: "+";
                position: absolute;
                right: 9px;
                top: 50%;
                margin-top: -11px;
                color: #888;
                border-radius: 30px 30px;
                font-size: 20px;
            }
        }
    }
}

