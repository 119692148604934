/* Brand Area */
.brand-slider{
    .slick-slide{
        display: flex !important;
        justify-content: center;
        @media #{$sm-layout} {
            display: block !important;
        }
    }
    .slick-slide > div{
        display: flex;
        justify-content: center;
    }
}

ul {
    &.three-col{
        li{
            flex-basis: 33.33%; 
        }
    }
    &.brand-list {
        @extend %liststyle;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        // border-left: 1px solid rgba(0, 0, 0, 0.05);
        // border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        @media #{$sm-layout} {
            border-left: 0 none;
            border-bottom: 0 none;
        }
        li {
            flex-basis: 50%;
            text-align: center;

            // height: 214px;
            // line-height: 214px;
            // border-top: 1px solid rgba(0, 0, 0, 0.05);
            // border-right: 1px solid rgba(0, 0, 0, 0.05);
            @extend %transition;
            cursor: pointer;
            
            @media #{$sm-layout} {
                border-top: 0 none;
                border-right: 0 none;
                height: auto;
                line-height: 10;
            }
            img {
                opacity: 0.7;
                transition: 0.3s;
                display: block;
                margin: 22px 10px auto 10px;
                max-height: 80px;
                max-width: 150px;
                &.small-logo{
                    max-height: 55px;
                    @media #{$sm-layout}{
                       max-height: 45px;
                       width: auto;
                    }
                }
                @media #{$sm-layout}{
                    margin: 15px 10px 20px 10px;
                    max-height: 55px;
                    width: auto;
                }
            }

            &:hover{
                img{
                    opacity: 1;
                    filter: brightness(0) invert(1);
                }
            }
            
        }

        &.branstyle--2 {
            li {
                flex-basis: 33.33%;
            }
        }
    }
}

ul{
    &.brand-style-2 {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        @media #{$large-mobile} {
            margin: 0 -15px;
        }
        li {
            padding: 25px 25px 0 25px;
            flex-basis: 20%;
            text-align: center;
            @media #{$sm-layout} {
                flex-basis: 33.33%;
                padding: 17px 10px;
            }
            @media #{$large-mobile} {
                flex-basis: 33.33%;
                padding: 0 15px;
                margin-bottom: 16px;
            }
        }
        img {
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
            // opacity: 0.7;
            transition: 0.3s;
            max-height: 130px;
            max-width: 150px;
            cursor: pointer;
            @media #{$sm-layout} {
                max-height: 60px;
                max-width: 90px;
            }
            @media #{$lg-layout} {
                max-width: 129px;
            }
            @media #{$large-mobile} {
                max-height: initial;
                max-width: 100%;
            }
            &:hover{
                transform: scale(1.1);
                opacity: 1;
            }
        }
        svg{
            min-width: 55px;
            min-height: 55px;
        }
        h4{
            font-size: 25px;
        }
        
    }
}

ul{
    &.technologies-brand{
        img{
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%); 
        }
    }
}

.react-parallax-content{
    .brand-style-2 {
        li{

        }
        a {
            img {
                filter: brightness(0) invert(1);
            }
            &:hover {
                img {
                    filter: brightness(1) invert(0);
                }
            }
        }
    }
}

.brand-separation {
    position: relative;
    // padding-bottom: 217px;
    // margin-bottom: -117px;
    padding-top: 60px;
    background: #fff;
    
    @media #{$sm-layout} {
        margin-bottom: 0;
        padding-bottom: 60px;
    }

    @media #{$md-layout} {
        margin-bottom: 0;
        padding-bottom: 60px;
    }
}
.brand-separation.industries{
    background: #f8f9fc;
}
.custom-script.brand-separation{
    padding-top: 0;
    padding-bottom: 120px;
}
.bottom-border{
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 50px;
}

ul{
   &.three-col{
        li{
        flex: 1;
        @media #{$md-layout} {
            flex-basis:50%;
        }
        @media #{$sm-layout} {
            flex-basis:50%;
        }
        }

    }
}
ul{
    &.last-col{
        // li:nth-last-child(2){
        //     flex: 1;
        // }
        // li:last-child{
        //     flex: 1;
        // }
    }
}
.technologies-grid{

    h3{
        font-size: 35px;
        line-height: 45px;
        text-align: center;
        @media #{$md-layout} {
            font-size: 22px;
            line-height: 30px;
        }
        @media #{$sm-layout} {
            font-size: 22px;
            line-height: 30px;
        }
    }
    ul{
        li{
            display: flex;
            justify-content: center;
            // flex-direction: column;

        }
        
        li:hover img{
            filter: none;
        }
        li img{
            opacity: 1;
        }
        width: 100%;
       
    }
}







.designer-portfolio-area{
    .slick-prev, .slick-next{
      width: 50px;
      height: 50px;
      line-height: 49px;
      border-radius: 50%;
      background-color: #fff;
      text-align: center;
      overflow: hidden;
      z-index: 999 !important;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);

      @media(max-width:1400px){
        transform: translate(0, 110px);
      }
      &:before{
        height: 25px;
        width: 25px;
        display: block;
        z-index: 1;
        position: absolute;
        opacity: 1;
        background-size: 15px;
        content: " ";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-position: center center;
      }
      
    }
    
    .slick-next{
      right: -80px;
      @media(max-width:1400px){
        right: 50%;
        top: auto;
        bottom: -100%;
        margin-right: -55px;
      }
      &:before{
        
        background-image: url('data:image/svg+xml,<svg height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 185.343 185.343" xml:space="preserve" fill="%23000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><g><g><path style="fill:%23ff6f3b;" d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175 l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934 c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"></path></g></g></g></svg>');
      }
      
  
    }
  
    .slick-prev{
      left: -80px;
      @media(max-width:1400px){
        left: 50%;
        top: auto;
        bottom: -100%;
        margin-left: -55px;
      }
     
      &:before{
        transform: translate(-50%, -50%) rotate(180deg) ;
        background-image: url('data:image/svg+xml,<svg height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 185.343 185.343" xml:space="preserve" fill="%23000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><g><g><path style="fill:%23ff6f3b;" d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175 l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934 c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"></path></g></g></g></svg>');
      }
    }
  }
  
  .tech-buttons-slider{
    margin-left: -15px;
    margin-right: -15px;
    .tech-button{
      border: 1px solid #ebebeb;
      padding: 10px 5px;
      border-radius: 10px;
      margin-bottom: 15px;
      text-align: center;
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      @media #{$md-layout} {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 82px;
    }
    @media #{$sm-layout} {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 82px;
        font-size: 13px !important;
    }
    }
    .slick-slide {
      padding: 0 15px;
      &.slick-active.slick-current{
        p{
          background-color: #ff6f3b;
          color: #fff;
          border: 1px solid #ff6f3b;
        }
      }
    }
   
  
  }
  .tech-image-slider{
    margin-top: 50px;
    @media #{$md-layout} {
        margin-top: 20px;
    }
    @media #{$sm-layout} {
        margin-top: 20px;
    }
  }
  .technologies-grid .slick-prev, .technologies-grid .slick-next {
    width: 50px;
    height: 50px;
    line-height: 49px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    overflow: hidden;
    z-index: 999 !important;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12),0 3px 1px -2px rgba(0,0,0,.2);
}
.technologies-grid  .slick-prev:before {
    transform: translate(-50%, -50%) rotate(180deg) !important;
    background-image: url('data:image/svg+xml,<svg height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 185.343 185.343" xml:space="preserve" fill="%23000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><g><g><path style="fill:%23ff6f3b;" d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175 l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934 c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"></path></g></g></g></svg>');
}
.technologies-grid  .slick-prev{
    left: -80px;
}
.technologies-grid  .slick-next{
    right: -80px;
}
.technologies-grid  .slick-next:before {
    background-image: url('data:image/svg+xml,<svg height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 185.343 185.343" xml:space="preserve" fill="%23000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><g><g><path style="fill:%23ff6f3b;" d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175 l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934 c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"></path></g></g></g></svg>');
}
.technologies-grid .slick-prev:before, .technologies-grid .slick-next:before {
    height: 25px;
    width: 25px;
    display: block;
    z-index: 1;
    position: absolute;
    opacity: 1;
    background-size: 15px;
    content: " ";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-position: center center;
}
@media(max-width:1440px){
    .technologies-grid .slick-prev{
        left: -40px;
    }
    .technologies-grid .slick-next{
        right: -40px;
    }
}
@media(max-width:1024px){
    .technologies-grid .slick-prev{
        left: -18px;
        width: 35px;
    height: 35px;
    }
    .technologies-grid .slick-next{
        right: -18px;
        width: 35px;
    height: 35px;
    } 
}
@media(max-width:767px){
    .tech-image-slider .slick-arrow{
        display: none !important;
    }
    .technologies-grid .slick-prev{
        left: -10px; 
    }
    .technologies-grid .slick-next{
        right: -10px;
    } 
    .technologies-grid .slick-prev, .technologies-grid .slick-next{
        width: 35px;
        height: 35px;
    }
    .tech-buttons-slider .tech-button{
        margin-left: 20px;
        margin-right: 20px;
    }
}
@media(min-width:1200px) and (max-width:1350px){
    .technologies-grid  .slick-prev{
        left: 0;
    }
    .technologies-grid  .slick-next{
        right: 0;
    }
}


.tech-block{
    background: #FFFFFF;
    // box-shadow: 2px 4px 25px rgba(0, 0, 0, 0.12);
    border: 1px solid #ebebeb;
    border-radius: 15px;
    padding: 1px 12.6px 15px 12.6px;
    max-width: 100%;
    margin: 0px auto;
    margin-bottom: 37px;
    height: calc(100% - 37px);
    h4{
        text-align: center;
        color: #FFFFFF;
        padding: 23px 0px;
        margin-top: 12.36px;
        background: $theme-color;
        border-radius: 10px 10px 0px 0px;
        margin-bottom: 0;
    }
    .technologies-img{
        padding-left: 25px;
        flex: 0 0 100px;
        img{
            height: auto;
        }
    }
    .technologies-text{
        padding-left: 15px;
        font-size: 15px;
    }
    .technologies-our-features{
        margin: 0px;
        padding: 10px 0px;
        border-bottom: 1px solid #ebebeb;
    }
}