/*===================
Button Area 
====================*/
a {
    &.btn-transparent{
        color: #1f1f25;
        @extend %transition;
        font-size: 16px;
        font-weight: 500;
        position: relative;
        display: inline-block;
        padding-bottom: 3px;
        &:hover{
            color: $theme-color;
        }
        &::after{
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 40px;
            height: 2px;
            background: $theme-color;
            opacity: 0.75;
            @extend %transition;
        }
        &:hover{
            &::after{
                width: 100%;
            }
        }
    }
}

a ,
button {
    &.rn-btn {
        padding: 0 23px;
        height: 40px;
        display: inline-block;
        line-height: 34px;
        border: 2px solid $theme-color;
        border-radius: 4px;
        font-size: 14px;
        position: relative;
        z-index: 2;
        // font-family: Montserrat;
        color: $theme-color;
        letter-spacing: 0.2px;
        @extend %transition;
        text-transform: uppercase;
        font-weight: 500;
        &:hover {
            border: 2px solid $theme-color;
            transform: translateY(-3px);
            color: #ffffff;
            background: $theme-color;
        }
        &.btn-white {
            border: 2px solid #ffffff;
            color: #ffffff;
            &:hover {
                border-color: $theme-color;
            }
        }
    }
    &.started-btn{
        height: 38px !important;
        letter-spacing: 2px;
    }
}
a{
&.download-pdf-btn{
    letter-spacing: 2px;
    margin-left: 50px;
    font-weight: 500;
    color: #fff !important;
    border: 2px solid transparent !important;
    background-color: #ff6f3b;
    &:hover{
        color: #ff6f3b !important;
        border: 2px solid #ff6f3b !important;
        background-color: #fff !important;
    }
    @media #{$sm-layout} {
        letter-spacing: 0;

    }

    @media(min-width:450px) and (max-width:991px){
        margin-right: 30px; 
    }

}
}
button,
a {
    &.rn-button-style--2 {
        color: #c6c9d8;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 2px;
        border: 2px solid #b1b4c1;
        padding: 15px 40px;
        border-radius: 6px;
        display: inline-block;
        font-weight: 500;
        transition: 0.3s;
        @media #{$sm-layout} {
            padding: 9px 25px;
        }

        &.rn-btn-dark {
            border: 2px solid #1f1f25;
            color: #1f1f25;
            &:hover {
                background: #1f1f25;
                color: #fff;
            }
        }

        &.border-opacity-half{
            border-color: rgba(31, 31, 37, 0.1);
        }

        &.rn-btn-small {
            padding: 10px 20px;
        }

        &.btn-primary-color {
            &:hover {
                border: 2px solid $theme-color;
                background: $theme-color;
                color: #ffffff;
            }
        }

        &.btn-solid {
            border: 2px solid $theme-color;
            background: $theme-color;
            color: #ffffff;
        }

        &.btn-solid-border {
            border: 2px solid $theme-color;
            background: transparent;
            color: $theme-color;
            &:hover{
                border: 2px solid $theme-color;
                background: $theme-color;
                color: #ffffff;
                transform: translateY(-5px);
            }
        }

        &.rn-btn-small-2 {
            padding: 11px 15px;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 13px;
        }

        &.btn-solid {
            background: $theme-color;
            color: #ffffff;
        }

        &:hover{
            background: transparent;
            color: $theme-color;
            box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
            transform: translateY(-5px);
        }
    }
}
.submit-btn{
    font-family: "Poppins", sans-serif;
}



/*----------------------------
    Video Popup Styles 
-------------------------------*/
button {
    &.video-popup {
        display: block;
        position: relative;
        margin: 0 auto;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background: #fff;
        -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        -o-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 0;
            -webkit-transform: translate(-6px, -9px);
            -ms-transform: translate(-6px, -9px);
            transform: translate(-6px, -9px);
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-left: 15px solid $theme-color;
        }
        &:hover {
            -webkit-transform: scale3d(1.15, 1.15, 1.15);
            transform: scale3d(1.15, 1.15, 1.15);
        }
    }
}

button {
    &.video-popup {
        &.position-top-center {
            position: absolute;
            top: 50%;
            left: 50%;
            border: 0 none;
            width: 110px;
            height: 110px;
            margin-left: -55px;
            margin-top: -55px;
            transition: all 1s cubic-bezier(0, 0, 0.2, 1);
            box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
            @media #{$sm-layout} {
                width: 70px;
                height: 70px;
                margin-left: -35px;
                margin-top: -35px;
            }
            &:hover{
                transform: scale3d(1.15, 1.15, 1.15);
            }
            &.md-size {
                width: 80px;
                height: 80px;
                margin-left: -40px;
                margin-top: -40px;
            }
        }
        &.theme-color {
            background: $theme-color;
            border-color: $theme-color;
            &::before{
                border-left: 15px solid #ffffff;
            }
        }
        &.black-color {
            background: #000000;
            border-color: #000000;
            &::before{
                border-left: 15px solid #ffffff;
            }
        }
    }
}
.modal-wrap {
    padding: 60px 25px 60px 25px;
    display: flex;
    justify-content: center;
    // align-items: center;
}
.modal-body-wrap{
    margin-bottom: 25px;
}
.modal-wrap .model-left{
    text-align: center;
    flex: 0 0 35%;
}

.modal-wrap .model-right{
    flex: 1;
}
.modal-wrap .model-right p{
    font-size: 16px;
    line-height: 26px;
}
.modal-dialog {
    max-width: 750px;
    margin: 1.75rem auto;
    top: 50%;
    transform: translateY(-50%) !important;
}
.model-close-btn {
    position: absolute;
    top: 0;
    right: 10px;
    top: 11px;
}
.model-close-btn img{
    max-width: 28px;
    cursor: pointer;
}
.header-area .header-wrapper a.rn-btn.download-pdf-btn.mobile-none{
    display: none;
}
.modal-content{
    border: 2px solid #ff6f3b;
}
.modal-wrap .model-left .logo-wrapper img {
    max-width: 127px;
    height: auto;
}

@media(max-width:681px){
    .modal-dialog{
        margin-left: 15px;
        margin-right: 15px;
    }
    .modal-wrap{
        padding: 25px 18px;
    }
    .modal-wrap{
        flex-direction: column;
    }
    .modal-wrap  .model-right{
        text-align: center;
    }
    .checkbox-wrapper label{
        text-align: left;
    }

}
@media(max-width:767px){
    .header-area .header-wrapper a.rn-btn.download-pdf-btn{
        display: block;
    }
    .header-area .header-wrapper a.rn-btn.download-pdf-btn.mobile-none{
        display: block;
    }
    .header-area .header-wrapper a.rn-btn.download-pdf-btn.desktop-none{
        display: none;
    }
    a.download-pdf-btn {
        margin-left: 0px;

    }
}
.modal-footer-wrap input[type="submit"]{
    width: auto;
    background: #ff6f3b;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 2px solid transparent;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
    transition: .3s;
    font-family: Poppins, sans-serif;

}
.modal-footer-wrap input[type="submit"]:hover{
    border: 2px solid #ff6f3b;
    background-color: #fff;
    color: #ff6f3b;
}
.start-project-wrapper {
    border: 1px solid #ff6f3b;
    border-radius: 8px;
    padding: 35px 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}
.start-project-wrapper .text-left{
    display: flex;
    align-items: center;
}
.modal-footer-wrap{
    display: flex;
    align-items: center;
}

@media(max-width:767px){
    .start-project-wrapper p{
        margin-bottom: 18px;
    }
}