/*==========================
    Service Area 
===========================*/

.service{
    // Service Style One
    &.service__style--1 {
        padding: 40px 0;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        @media #{$sm-layout} {
            padding: 15px 0;
        }
        @media #{$md-layout} {
            padding: 20px 0;
        }
        .icon {
            img {
                margin-bottom: 34px;
                @media #{$sm-layout} {
                    margin-bottom: 14px;
                    height: 53px;
                }
                @media #{$large-mobile} {
                    margin-bottom: 13px;
                    height: 45px;
                }
            }
        }
        .content {
            h4,.h2 {
                &.title {
                    margin-bottom: 20px;
                    font-weight: 400;
                    font-size: 24px;
                    @media #{$sm-layout} {
                        margin-bottom: 12px;
                        font-weight: 400;
                        font-size: 20px;
                    }
                    @media #{$large-mobile} {
                        margin-bottom: 9px;
                        font-size: 18px;
                    }
                    
                }
            }
            p {
                opacity: 0.75;
                font-weight: 300;
            }
        }
    }
    
    &.service__style--2 {
        display: flex;
        gap: 30px;
        // padding: 30px 35px;
        padding: 30px 20px;
        @extend %transition;
        @extend %posrelative;
        z-index: 2;
        border-radius: 10px;
        height: 100%;
        @media #{$laptop-device} {
            padding: 30px 28px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
            flex-direction: column;
            gap:0;
        }
        @media #{$sm-layout} {
            padding: 30px 14px;
            flex-direction: column;
            gap:0;
        }
        @media #{$large-mobile} {
            padding: 30px 18px;
            margin-top: 30px;
            flex-direction: column;
            gap:0;
            height: auto;
        }

        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: linear-gradient(to right, $theme-color-2 , $theme-color-2 );
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            border-radius: 10px;
            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
        }
        .icon {
            font-size: 54px;
            font-weight: 400;
            // margin-bottom: 23px;
            margin-bottom: 14px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            @media #{$md-layout} {
                margin-bottom: 11px;
            }
            @media #{$large-mobile} {
                color: #ffffff;
            }
           svg {
                // stroke-width: 1 !important;
           }
        }
        .content {
            h3,.h3 {
                &.title {
                    // margin-bottom: 19px;
                    margin-bottom: 10px;
                    font-weight: 500;
                    font-size: 18px;
                    @extend %transition;
                    @media #{$laptop-device} {
                        font-size: 18px;
                    }
                    @media #{$sm-layout} {
                        font-size: 18px;
                    }
                    @media #{$md-layout} {
                        margin-bottom: 8px;
                    }
                    @media #{$large-mobile} {
                        color: #ffffff;
                        font-size: 22px;
                        font-weight: 600;
                    }
                    a {
                        color: inherit;
                        @media #{$large-mobile} {
                            color: #ffffff;
                        }
                    }
                }
            }
            p {
                font-size: 16px;
                @extend %transition;
                color: rgba(29,29,36,0.75);
                @media #{$large-mobile} {
                    color: #ffffff;
                }

            }
        }

        &:hover{
            box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .icon{
                color: #ffffff;
            }
            .content{
                h3,.h3,.tech-title {
                    &.title{
                        @extend %textwhite;        
                    }
                }
                p{
                    @extend %textwhite;  
                }
            }
        }

        &.service-left-align {
            display: flex;
            padding: 50px 24px;
            .icon {
                padding-right: 20px;
            }
        }
    }



    // Service Style Four 
    &.service__style--4 {
        padding: 53px 40px;
        z-index: 2;
        border-radius: 10px; 
        position: relative;
        @extend %transition;
        background: #191919;
        height: 100%;
        cursor: pointer;
        @media #{$laptop-device} {
            padding: 36px 25px;
        }
        @media #{$lg-layout} {
            padding: 25px 20px;
        }
        @media #{$md-layout} {
            padding: 30px 25px;
        }
        @media #{$sm-layout} {
            padding: 25px 20px;
        }
        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background-image: -webkit-gradient(linear,left top,right top,from(#f61b10),to(#ef0963));
            background-image: -webkit-linear-gradient(left,#f61b10,#ef0963);
            background-image: linear-gradient(90deg,#f61b10,#ef0963);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            border-radius: 10px;
            @extend %transition;
            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
        }
        
        .icon {
            display: inline-flex;
            font-size: 54px;
            font-weight: 400;
            margin-bottom: 23px;
            display: inline-flex;
            color: $theme-color;
            @extend %transition;
            @media #{$sm-layout} {
                margin-bottom: 18px;
            }
            @media #{$large-mobile} {
                color: #fff;
            }
            svg {
                stroke-width: 1 !important;
            }
        }
        .content {
            h3 {
                &.title {
                    margin-bottom: 19px;
                    font-weight: 500;
                    font-size: 22px;
                    @extend %transition;
                    @media #{$laptop-device} {
                        margin-bottom: 12px;
                        font-size: 20px;
                    }
                    @media #{$large-mobile} {
                        color: #fff;
                    }
                    @media #{$sm-layout} {
                        margin-bottom: 10px;
                        font-size: 18px;
                    }
                }
            }
            p {
                color: rgba(29,29,36,.75);
                @extend %transition;
                @media #{$large-mobile} {
                    color: #fff;
                }
            }
        }
        &:hover {
            box-shadow: 0 10px 25px 10px rgba(0,0,0,.1);
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .icon {
                color: #fff;
            }
            .content {
                h3 {
                    &.title {
                        color: #fff;
                    }
                }
                p {
                    color: #fff;
                }
            }
        }
    }
    
   
    
}

/* ---------------------------
    Standard Service  
-------------------------------*/
.standard-service {
    .thumbnai {
        img {
            border-radius: 5px;
        }
    }
    .content{
        h3 {
            font-size: 19px;
            margin-top: 30px;
            margin-bottom: 10px;
            font-weight: 600;
            a {
                @extend %transition;
                color: $heading-color;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        p {
            font-size: 15px;
            line-height: 24px;
        }
        a {

        }
    }
}



.text-center ,
.text-left {
    .service {
        &.service__style--2 {
            padding: 60px 45px;
            @media #{$sm-layout} {
                padding: 30px 14px;
            }
            @media #{$lg-layout} {
                padding: 30px 14px;
            }
            @media #{$md-layout} {
                padding: 30px 14px;
            }
        }
    }
}

// Sercvice Style One
.service-white{
    // Service Style One
    .service__style--1 {
        z-index: 2;
        .content {
            h4 ,h2,.h2{
                &.title {
                   color: #c6c9d8;
                }
            }
            p{
                color: #c6c9d8;

            }
        }
        .icon{
            img{
                max-width: 65px;
                max-height: 65px;
            }
        }
    }
    .service-card{
        margin-top: 50px;
        padding: 40px 0;
        .service-card__icon{
            img{
                max-width: 65px;
                max-height: 65px;
                margin-bottom: 34px;
            }
            
        }
           
        .service-card__content{
            .service-card__title,.service-card__description{
                color: #c6c9d8;
            }
            .service-card__title{
                margin-bottom: 20px;
                font-weight: 400;
                font-size: 24px;
            }
            .service-card__description{
                opacity: .75;
                font-weight: 300;
            }

        }
    }
}

/*---------------------------
    Paralax Service Style  
-----------------------------*/

.rn-paralax-service {
    .service__style--2 {
        z-index: 2;
        padding: 40px 40px;
        background: rgba(255,255,255, 0.3);
        margin-top: 40px;
        overflow: hidden;
        border: 2px solid transparent;
        
        @media #{$sm-layout} {
            padding: 30px 20px;
            background: transparent;
            margin-top: 30px;
        }
        
        .icon {
            color: #ffffff;
        }
        .content {
            h3,.h3,.tech-title {
                &.title {
                    font-size: 19px;
                    color: #ffffff;
                }
            }
            p {
                color: rgba(255,255,255, 0.75);
            }
        }
        &:hover {
            border: 2px solid $theme-color;
            &::before {
                border-radius: 0;
            }
        }
    }
}

/* Creative Agency Service  */
.creative-service-wrapper {
    .row {
        &.creative-service {
            a{
                display: flex;
                height: 100%;
            }
            .service {

                &.service__style--2 {
                    margin-top: 30px;
                    background: #f6f6f6;
                }
            }
        }
    }
    
}



.service-one-wrapper{
    @media #{$md-layout} {
        margin: 0 -40px;
    }
}
.newsletter-container{
    .newsletter-succes-text{
        font-size: 12px;
    }
    h2{
        color: #1f1f25;
        word-spacing: 8px;
    }
    p{
        color: #1f1f25; 
    }
    a,a:visited{
        color: #1f1f25; 
        &:hover{
            color:#ff6f3b ;
        }
    }
    span {
        color: #1f1f25; 
    };
    #button-addon2{
    position: absolute;
    right: 0;
    border: none;
    height: 100%;
    z-index: 5;
    padding: 15px 25px;

    svg{
        min-height: 32px;
        min-width: 32px;
        fill: #FF6F3B;
    }
}
#button-addon2:focus{
    outline: none;
    border-color: transparent;
    box-shadow: none;
}
input{
    min-height: 65px;
    z-index: 3;
}
input:focus{
    outline: none;
    border: 1px solid #ced4da;
    box-shadow: none;
}
p{
    // max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    color: #1f1f25;
    @media #{$sm-layout} {
        max-width: 100%;
    }
}

}

.bg-light-orange{
    background-color: #FF6F3B;
}

.text-decoration-underline{
    text-decoration: underline;
}
.text-decoration-underline:hover{
    text-decoration: underline;
}
.privacy-bottom-text{
    font-size: 12px;
    margin-top: 30px;
}

.custom-service-wrapper{
    .service.service__style--2 .content h3.title,
    .service.service__style--2 .content .h3.title{
        @media #{$large-mobile} {
            color: #ff6f3b ;
            font-size: 17px;

        }
    }
    @media #{$large-mobile}{
    .service.service__style--2 svg{
        fill: #ff6f3b ;
    }
}

}







